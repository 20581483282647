<template>
  <v-card class="d-flex flex-column flex">
    <v-card-title>
      {{ location.label }}
    </v-card-title>
    <v-card-subtitle>
      {{ location.description }}
    </v-card-subtitle>
    <v-card-text class="caption pb-0">
      <div>
        <div class="font-weight-bold">
          UUID:
        </div>
        <div>{{ location.uuid }}</div>
      </div>
    </v-card-text>
    <v-card-text>
      <!--Notar maður this keywoard í ternary í HTML?????-->
      {{ $t('waitingTime') }}: <span class="font-weight-bold">{{ location.ept ? Math.floor(location.ept / 60) + $t('minutes') : $t('unassigned') }}</span> -
      <a
        target="_blank"
        link
        :href="'https://location.salescloud.is/#/?organization=' + organization.uuid + '&l=' + location.uuid "
      >{{ $t('edit') }}</a>
    </v-card-text>
    <v-spacer />
    <v-card-actions>
      <v-btn
        link
        :to="'/store/locations/' + location.uuid"
        text
        color="primary"
      >
        {{ $t('zones') }}
      </v-btn>
      <v-btn
        link
        :to="'/store/locations/'+ location.uuid +'/qr-print'"
        color="blue"
        dark
        depressed
        v-if="locationHasTables"
      >
        {{ $t('qrPrint') }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        :to="'/store/locations/' + location.uuid + '/edit'"
      >
        {{ $t('manage') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
	name: 'LocationCard',
  props: {
    location: {
      type: Object, 
      default: () => {}
    }
  },
	data: () => ({

	}),
	computed: {
		organization: function() {
			return this.$store.state.organization
		},
		locationHasTables() {
			return this.location && Array.isArray(this.location.variations) && this.location.variations.length > 0
		}
	},
	methods: {

	}
}
</script>
