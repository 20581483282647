<template>
  <div>
    <div v-if="locations === null">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </div>
    <div v-if="locations !== null">
      <v-row>
        <template v-for="(location, index) in locations">
          <v-col
            :key="index"
            cols="12"
            sm="6"
            md="4"
            xl="3"
            class="d-flex flex-column"
          >
            <LocationCard :location="location" />
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>

  import LocationCard from "./LocationCard"

  export default {
    name: 'LocationGrid',
    props: {
      locations: {
        type: Array, 
        default: () => []
      }
    },
    components: {
      LocationCard
    },
    data: () => ({
    }),
    methods: {
    }
  }
</script>
